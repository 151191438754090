$(document).ready(function () {
  $("body").css("visibility", "visible");
  $("body").addClass("fade");

  var domain = "https://madrecocina.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var reservation_ = domain + "reservation";
  var gallery_ = domain + "gallery";
  var contact_ = domain + "contact";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "reservation", file: reservation_ },
    { page: "gallery", file: gallery_ },
    { page: "contact", file: contact_ },
  ];

  function initNavigationEvents() {
    $(".home, .about, .menu, .reservation, .gallery, .contact").each(
      function () {
        $(this).click(function () {
          var clickedClass = $(this).attr("class").split(" ")[0];
          var pageObj = pages.find(function (page) {
            return page.page === clickedClass;
          });
          if (pageObj) {
            var file = pageObj.file;
            window.location.href = file;
          }
        });
      }
    );
  }

  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname
      .replace(/\s+/g, "-")
      .toLowerCase();

    $(".page").each(function () {
      var linkClass = $(this)
        .attr("class")
        .split(" ")
        .find((cls) => cls !== "page");
      if (linkClass && currentPath.includes(linkClass)) {
        $(this).addClass("activo");
      }
    });

    if (currentPath.endsWith("/")) {
      $(".home").addClass("activo");
    }
  }
  $(".openPopupButton").on("click", function () {
    console.log("Botón clickeado:", this); // Esto te mostrará en consola si el evento se dispara y cuál es el elemento
  });

  function openOverlay() {
    $(".openPopupButton").on("click", function () {
      const overlay = $("#overlay");
      const widgetContainer = $("#widgetContainer");
      $("body").css("overflow", "hidden");

      // Si el contenedor ya tiene el widget, no lo elimines ni lo vuelvas a agregar
      if (!$("#opentableWidget").length) {
        widgetContainer.empty();

        // Agregar el script de OpenTable
        const script = document.createElement("script");
        script.id = "opentableWidget";
        script.type = "text/javascript";
        script.src =
          "//www.opentable.com/widget/reservation/loader?rid=1387450&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&cfe=true";

        script.onload = function () {
          console.log("OpenTable widget loaded successfully.");
        };

        script.onerror = function () {
          console.error("Error loading the OpenTable widget.");
        };

        widgetContainer[0].appendChild(script);
      }

      overlay.css("display", "flex"); // Mostrar el overlay después de agregar el script
    });

    // Close the popup
    $("#closePopupButton").on("click", function () {
      $("#overlay").css("display", "none");
      $("body").css("overflow", "visible");
    });

    // Close the popup when clicking outside of it
    $(window).on("click", function (event) {
      if (event.target === $("#overlay")[0]) {
        $("#overlay").css("display", "none");
        $("body").css("overflow", "visible");
      }
    });
  }

  $("header").load("header.html", function () {
    // Toggle menu visibility on button click
    $(".cls_opn").on("click", function (event) {
      event.stopPropagation(); // Prevent click event from propagating to the document
      $("header nav").toggleClass("unhide");
      $("header").toggleClass("black");
      $(this).toggleClass("resize");
      $(".btn_header").toggleClass("rsz");
    });

    $("i.close-icon").on("click", function () {
      $("header nav").removeClass("unhide");
      $("header").removeClass("black");
      $(".cls_opn").removeClass("resize");
      $(".btn_header").removeClass("rsz");
    });

    // Close the menu if clicking outside of header nav
    $(document).on("click", function (event) {
      if (
        !$(event.target).closest("header nav").length &&
        !$(event.target).closest(".cls_opn").length
      ) {
        $("header nav").removeClass("unhide");
        $("header").removeClass("black");
        $(".cls_opn").removeClass("resize");
        $(".btn_header").removeClass("rsz");
      }
    });

    // Eventos adicionales
    $(".logo").on("click", onclickLogo);

    initNavigationEvents();
    agregarClaseNavActivo();
    openOverlay();
  });

  $("footer").load("footer.html", function () {
    $(".year").each(function () {
      var currentDate = new Date();
      var ANHO = currentDate.getFullYear();
      $(this).text(ANHO);
    });

    $(".ig").on("click", onClickInstagram);
    $(".fb").on("click", onClickFacebook);

    location_address(address_text, address_link);
    location_phone(phone_number);
    location_email(email_address);

    $(".comales_website").on("click", onclickLogoComales);
  });

  $(document).ready(function () {
    if (
      window.location.pathname.includes("index.html") ||
      window.location.pathname === "/" ||
      window.location.pathname.includes("menu.html") ||
      window.location.pathname === "/menu"
    ) {
      const $buttons = $("ul li.button");
      const $menus = $(".menu");

      // Make the first menu visible and add "checked" to the first button by default
      $menus.first().addClass("active");
      $buttons.first().addClass("checked");

      // Add click event to each button
      $buttons.each(function (index) {
        $(this).on("touchstart click", function (e) {
          e.preventDefault(); // Evitar el comportamiento predeterminado
          $menus.removeClass("active");
          $buttons.removeClass("checked");

          // Mostrar el menú correspondiente y agregar la clase "checked"
          $menus.eq(index).addClass("active");
          $(this).addClass("checked");
        });
      });
    }

    if (
      window.location.pathname.includes("gallery.html") ||
      window.location.pathname === "/gallery"
    ) {
      $('[data-fancybox="gallery"]').fancybox({
        hash: false,
      });
    }

    if (
      window.location.pathname.includes("index.html") ||
      window.location.pathname === "/" ||
      window.location.pathname.includes("menu.html") ||
      window.location.pathname === "/menu"
    ) {
      $(
        '[data-fancybox="menu_dinner"],[data-fancybox="menu_brunch"],[data-fancybox="menu_drinks"]'
      ).fancybox({
        hash: false,
      });
    }
  });

  $(document).ready(function () {
    $(window).on("scroll", function () {
      const header = $("header");

      if ($(window).scrollTop() > 0) {
        header.addClass("colorea");
      } else {
        header.removeClass("colorea");
      }
    });
  });

  function onClickInstagram() {
    window.open("https://www.instagram.com/madre.cocina/", "_blank");
  }

  function onClickFacebook() {
    window.open(
      "https://www.facebook.com/profile.php?id=61563290576964",
      "_blank"
    );
  }

  function onclickLogoComales() {
    window.open("https://taqueriafortcollins.com/", "_blank");
  }

  function onclickLogo() {
    window.location.href = domain;
  }

  var currentDate = new Date().toISOString();
  var ogUpdatedTimeMeta = $("#ogUpdatedTime");
  if (ogUpdatedTimeMeta.length > 0) {
    ogUpdatedTimeMeta.attr("content", currentDate);
  }

  let address_text = "214 Linden St, Fort Collins, CO 80524";
  let address_link = "https://maps.app.goo.gl/kjBkVAVGSgybLszo7";
  let phone_number = "(970) 825-5878";
  let email_address = "madrecocinaco@gmail.com";

  function location_address(address_text, address_link) {
    $(".location_address").each(function () {
      $(this).text(address_text);
      $(this)
        .wrap('<a target="_blank"></a>')
        .parent()
        .attr("href", address_link);
    });
  }

  function location_phone(phone_number) {
    $(".location_phone").each(function () {
      // Set the text content to the phone number
      $(this).text(phone_number);

      // Wrap the element in an anchor tag with the "tel:" link
      $(this)
        .wrap("<a></a>")
        .parent()
        .attr("href", "tel:" + phone_number);
    });
  }

  function location_email(email_address) {
    $(".location_email").each(function () {
      // Set the text content to the email address
      $(this).text(email_address);

      // Wrap the element in an anchor tag with the "mailto:" link
      $(this)
        .wrap("<a></a>")
        .parent()
        .attr("href", "mailto:" + email_address);
    });
  }

  $(document).ready(function () {
    // Add a timestamp to all image URLs to avoid caching
    $("img").each(function () {
      var src = $(this).attr("src");
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });

    // Add a timestamp to all script URLs to avoid caching
    $("script[src]").each(function () {
      var src = $(this).attr("src");
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });

    // Add a timestamp to all video source URLs to avoid caching
    $("video").each(function () {
      var $source = $(this).find("source");

      // If there is a <source> element with a src attribute
      if ($source.length) {
        var src = $source.attr("src");
        $source.attr("src", src + "?v=" + new Date().getTime());

        // Reload the video to apply the new src
        this.load();
      }
    });
  });

  $(document).ready(function () {
    $(".toUp").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });
  });
});
